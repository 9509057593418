<template>
  <div class="Pagination">
    <a-pagination
      :locale="localeData"
      :show-size-changer="showSizeChanger"
      :pageSizeOptions="itemsPerPageOptions"
      :default-current="currentPage"
      :total="totalItems"
      :defaultPageSize="defaultItemsPerPage"
      :simple="simpleView"
      :size="size"
      :current="currentPage"
      :hideOnSinglePage="hideOnSinglePage"
      @change="(currentPage, itemsPerPage) => $emit('changePage', {currentPage, itemsPerPage})"
      @showSizeChange="(currentPage, itemsPerPage) => $emit('itemPerPageHandler', {currentPage, itemsPerPage})"
    />
  </div>
</template>


<script>
import { Pagination } from 'ant-design-vue';

export default {
  name: "CustomPagination",
  components: {
    APagination: Pagination,
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      required: true,
    },
    simpleView: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    defaultItemsPerPage: {
      type: Number,
      default: 10,
    },
    hideOnSinglePage: {
      type: Boolean,
      default: false,
    },
    showSizeChanger: {
      type: Boolean,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => ['10', '20', '30', '40', '50', '100']
    }
  },
  data() {
    return {
      localeData: {
        items_per_page: 'per pagina'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.Pagination {
  margin-top: 20px;
  text-align: center;
}
</style>
