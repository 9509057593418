<template>
  <div>
    <a-card
      v-for="shippedProduct of shippedProducts"
      :key="shippedProduct.id"
      :hoverable="true"
      :bordered="true"
      class="card"
    >
      <div>
        <a-list
          :bordered="false"
          size="large"
        >
          <a-list-item class="item-details-wrapper">
            <div
              @click="shippedProduct.show = !shippedProduct.show"
              class="item-details"
            >
              <div class="item-details__header">
                <p
                  v-for="headerProductItem of shippedProduct.header"
                  :key="headerProductItem.value"
                  :style="{ width: widthOfColumns }"
                  class="item-details__header-item"
                >
                  {{ headerProductItem.value }}
                </p>
              </div>
              <a-icon
                type="down"
                class="item-details__header-icon"
                :rotate="shippedProduct.show ? 180 : 0"
              />
            </div>
            <transition name="slide">
              <div
                v-show="shippedProduct.show"
                style="width: 100%"
              >
                <div class="item-details__body">
                  <div class="item-details__progress">
                    <BookingProgressStep
                      v-for="(obj, idx) in steps"
                      :key="obj.id"
                      :step="idx + 1"
                      :steps-count="steps.length"
                      :isGrey="steps.findIndex(step => step.id === shippedProduct.status.id) < idx"
                      :title="obj.title"
                      :text="obj.text"
                      :imgUrl="obj.imgUrl"
                    />
                  </div>
                  <a-list
                    v-for="(packageItem, index) of shippedProduct.shipmentProducts"
                    :key="index"
                    size="large"
                    :bordered="true"
                    class="item-details__product-list"
                  >
                    <h2
                      slot="header"
                      class="item-details__product-title"
                    >
                      Product №{{ index + 1 }}
                    </h2>
                    <a-list-item v-for="(field, key) of packageItem" :key="field.dictionary + field.value">
                      <div class="item-details__product-list-item">
                        <span>
                          <b>
                            {{ field.dictionary || '---' }}
                          </b>
                        </span>
                        <span class="item-details__product-list-item-value">
                          {{ field.value | modifyValue(key) }}
                        </span>
                      </div>
                    </a-list-item>
                  </a-list>
                  <a-list
                    size="large"
                    :bordered="true"
                  >
                    <h2
                      slot="header"
                      class="item-details__product-title"
                    >
                      Verzending
                    </h2>
                    <a-list-item v-for="(deliveryItem, key) of shippedProduct.shipmentDelivery" :key="deliveryItem.dictionary + deliveryItem.value">
                      <div class="item-details__product-list-item">
                        <span>
                          <b>
                            {{ deliveryItem.dictionary || '---' }}
                          </b>
                        </span>
                        <span class="item-details__product-list-item-value">
                          {{ deliveryItem.value | modifyValue(key) }}
                        </span>
                      </div>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
            </transition>
          </a-list-item>
        </a-list>
      </div>
    </a-card>
  </div>
</template>

<script>
import BookingProgressStep from "../dashboard/BookingProgressStep";
import fifthImg from "@/assets/dashboard/delivery.svg"
import firstImg from "@/assets/dashboard/booking_created.svg"

export default {
  name: "WarehouseShippedItem",
  data() {
    return {
      steps: [
        {
          imgUrl: firstImg,
          title: 'Voorbereiding',
          text: 'Voorbereiding',
          id: 5
        },
        {
          imgUrl: fifthImg,
          title: 'Levering',
          text: 'Levering',
          id: 6,
        },
      ],
    }
  },
  filters: {
    modifyValue: function (val, fieldName) {
      if (fieldName === 'lvb') return val ? 'Ja' : 'Nee'
      if (fieldName === 'countOfPallets') return val === 0 ? 0 : val ? val : '---'
      return val || '---'
    }
  },
  components: {
    BookingProgressStep,
  },
  props: {
    shippedProducts: Array,
    widthOfColumns: String
  }
}
</script>

<style scoped lang="scss">
.card {
  border-radius: 4px; margin-bottom: 15px;
}
.item-details-wrapper {
  min-height: 55px;
  display: flex;
  flex-direction: column;
}
.item-details {
  width: 100%;
  display: flex;
  align-items: center;
  &__header {
    display: flex;
    flex-grow: 1;
  }
  &__header-item {
    padding-right: 25px;
    margin: 0;
    font-size: 16px;
  }
  &__header_icon {
    margin-left: auto;
  }
  &__body {
    padding-top: 40px
  }
  &__progress {
    display: flex;
    justify-content: space-between
  }
  &__product-list {
    margin-bottom: 15px
  }
  &__product-title {
    margin-bottom: 0
  }
  &__product-list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__product-list-item-value {
    font-size: 16px;
    max-width: 700px;
  }
}
</style>
