<template>
  <div
    class="wrapper"
    :style="{width: `${ 100 / stepsCount }%`}"
    :class="{greyScale: isGrey, 'wrapper--transitional-left': transitionalStepLeft, 'wrapper--transitional-right': transitionalStepRight}"
  >
    <img :src="imgUrl"
         class="img"
         :alt="titleText"
    >
    <div 
        class="step" 
        :class="{'step--transitional': transitionalStepLeft || transitionalStepRight, 'step--transitionalActive': isActive}"
    >
        {{ step }}
    </div>
    <h3 v-if="title" class="title">{{ title }}</h3>
    <p class="text" :class="{'text--positioned': transitionalStepLeft || transitionalStepRight}">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "BookingProgressStep",
  props: {
    isGrey: Boolean,
    step: Number,
    stepsCount: Number,
    imgUrl: String,
    title: String,
    text: String
  },
  computed: {
    titleText() {
      return this.imgUrl ? this.title : null; 
    }, 
    transitionalStepLeft() {
      return this.step === 4
    },
    transitionalStepRight() {
      return this.step === 6
    },
    isActive() {
      return (this.step === 4 || this.step === 6) && !this.isGrey
    }
  }
}
</script>

<style scoped lang="scss">
.img {
  height: 120px;
}

.wrapper {
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 16.666%;

  &:last-child {
    .step {
      &::after {
        display: none;
      }
    }
  }
  &--transitional {
    position: absolute;
    z-index: 100;
    height: 100%;
    top: 0;
  }
  &--transitional-left {
    @extend .wrapper--transitional;
    left: calc(50% - (51.5% / 6));
    transform: translateX(-50% + (51.5% / 6));
  }
  &--transitional-right {
    @extend .wrapper--transitional;
    right: calc(50% - (51.5% / 6));
    transform: translateX(50% - (51.5% / 6));
  }
}

.wrapper.greyScale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.step {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #FFA300;
  font-size: 0.75rem;
  border-radius: 50%;
  margin: 10px 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% + 20px);
    right: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: #878787;
  }

  &--transitional {
    width: 18px;
    height: 18px;
    margin: 13px auto;

    &::after {
      display: none;
    }
  }
  
  &--transitionalActive {
    cursor: pointer;
  }
  &--transitionalActive:hover + .title--positioned,
  &--transitionalActive:hover ~ .text--positioned {
    cursor: pointer;
    opacity: 1;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.title {
  font-size: 14px;
  line-height: 1.7;
  color: #263238;
}

.text {
  max-width: 75%;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.8;
  color: #71797e;
  transition: all 300ms ease-in;

  &--positioned {
    margin: 0;
    position: absolute;
    width: 130%;
    max-width: 130%;
    opacity: 0;
    background: #fff;
    z-index: -1;
  }
}
</style>
