<template>
  <div
    class="Spinner Spinner__wrapper"
    :class="{ 'Spinner__absolute': centered, 'Spinner__absolute-bottom': bottomed }"
  >
    <a-spin>
      <a-icon slot="indicator" :type="type" :style="style" spin />
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "CustomSpinner",
  props: {
    type: {
      type: String,
      default: "loading",
    },
    size: {
      type: [String, Number],
      default: 24,
    },
    color: String,
    centered: {
      type: Boolean,
      default: false,
    },
    bottomed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `font-size: ${this.size}px; color: ${this.color}`;
    },
  },
};
</script>
      
<style lang="scss" scoped>
.Spinner {
  &__absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  &__absolute-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
      z-index: 999;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
