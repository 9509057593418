<template>
  <div style="height: 100%">
    <h1>Verzonden</h1>
    <transition
      name="component-fade"
      mode="out-in"
    >
      <a-empty
        v-if="!isShippedLoading && !shippedProducts.length"
        class="empty"
      />
      <div v-else>
        <a-card
        v-if="shippedProducts.length"
          :bordered="false"
          :hoverable="true"
          class="header-card"
        >
          <div class="header">
            <p
              v-for="col of cols"
              class="header-item"
              :style="{ width: widthOfColumns }"
              :key="col.view"
            >
              {{ col.view }}
            </p>
          </div>
        </a-card>
        <WarehouseShippedItem
          :shipped-products="shippedProducts"
          :width-of-columns="widthOfColumns"
        />
        <CustomPagination
          v-if="isAnyShippedProductExists"
          showSizeChanger
          :totalItems="totalItems"
          :itemPerPage="itemPerPage"
          :currentPage="currentPage"
          @changePage="paginationHandler"
          @itemPerPageHandler="itemPerPageHandler"
        />
        <CustomSpinner
          v-if="isShippedLoading"
          size="100"
          :centered="!isAnyShippedProductExists"
          :bottomed="isAnyShippedProductExists"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Spinner from "./Spinner";
import WarehouseShippedItem from "./WarehouseShippedItem";
import CustomPagination from "@/components/customComponents/CustomPagination";
import CustomSpinner from "@/components/customComponents/CustomSpinner";

export default {
  name: "WarehouseShipped",
  components: {
    Spinner,
    WarehouseShippedItem,
    CustomPagination,
    CustomSpinner
  },
  data() {
    return {
      cols: [
        {
          view: 'Zendingsreferentie',
        },
        {
          view: 'Persoonlijke referentie',
        },
        {
          view: 'Gemaakt op',
        },
        {
          view: 'Leveringsdatum ',
        },
        {
          view: 'Status',
        }
      ],
      defaultLoadingPageParams: {
        currentPage: 1, 
        itemsPerPage: 10
      }
    }
  },
  created () {
    this.fetchShippedProducts(this.defaultLoadingPageParams)
  },
  beforeDestroy () {
    this.UPDATE_PAGINATION();
    this.SET_SHIPPED_PRODUCTS({ products: [] })
  },
  computed: {
    ...mapGetters('warehouse', 
      [
        'isShippedLoading', 
        'shippedProducts',
        'itemPerPage',
        'totalItems',
        'currentPage',
      ]
    ),
    widthOfColumns() {
      return 100 / this.cols.length + '%'
    },
    isAnyShippedProductExists () {
      return !!(this.shippedProducts.length);
    },
  },
  methods: {
    ...mapActions('warehouse', ['fetchShippedProducts']),
    ...mapMutations('warehouse', ['UPDATE_PAGINATION', 'SET_SHIPPED_PRODUCTS']),
    fetchItems ({ currentPage, itemsPerPage }) {
      this.fetchShippedProducts({ currentPage, itemsPerPage })
        .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }));
    },
    paginationHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    },
    itemPerPageHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    }
  }
}
</script>

<style scoped lang="scss">
  .empty {
    height: 100%;
    max-height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-card {
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .header-item {
    padding-right: 25px;
    font-weight: 600;
    margin-bottom: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .header {
    display: flex;
    padding: 0 15px 0 0;
  }
</style>
