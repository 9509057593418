<template>
  <div class="spinner">
    <a-spin>
      <a-icon
        slot="indicator"
        type="loading"
        style="font-size: 46px"
        :spin="true"
      />
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "Spinner"
}
</script>

<style scoped>
  .spinner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
